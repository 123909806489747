<template>
  <div class="footer" id="footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-lg-3">
          <div class="social">
            <router-link to="/">
              <img class="logo" src="@/assets/logo.svg" alt="Logo">
            </router-link>
            <div class="social-media">
              <a target="_blank" :href="contacts.instagram"><img src="@/assets/icons/footer/instagram.svg" alt="Instagram Icon"></a>
              <a target="_blank" :href="contacts.facebook"><img src="@/assets/icons/footer/facebook.svg" alt="facebook Icon"></a>
              <a target="_blank" :href="contacts.x"><img src="@/assets/icons/footer/x.svg" alt="x Icon"></a>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3 col_ar">
          <div class="about">
            <h5>{{ $t('About') }}</h5>
            <ul>
              <li><router-link to="/AboutUs">{{ $t('About Us') }}</router-link></li>
              <li><router-link to="/ContactUs">{{ $t('Contact Us') }}</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3 col_ar">
          <div class="info">
            <h5>{{ $t('Info') }}</h5>
            <ul>
              <li><router-link to="/Terms">{{ $t('Terms & Conditions') }}</router-link></li>
              <li><router-link to="/PrivacyPolicy">{{ $t('Privacy & Policy') }}</router-link></li>
              <li><router-link to="/ReturnPolicy">{{ $t('Return Policy') }}</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-sm-6 col-lg-3 col_ar">
          <div class="contact">
            <h5>{{ $t('Contact') }}</h5>
            <p>{{ $t('Al-Shuhada Street Near Al Hamra Business') }}</p>
            <p>{{ $t('Tower 15452، Kuwait City') }}</p>
            <div class="email">
              <img src="@/assets/icons/footer/email.svg" alt="Email Icon">
              <a href="mailto:info@tinykoala.com">info@tinykoala.com</a>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="copy-write">
        <span>{{ $t('Copyright') }} &copy; {{ $t('023 Tiny Koala All Rights reserved!') }} </span>
        <span>{{ $t('Powered By Line') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import langStor from '@/langStore'

export default {
  name: "AppFooter",
  data() {
    return {
      contacts: {},
    }
  },
  methods: {
    getContacts() {
      fetch(this.serverURL + 'api/contacts', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200)
          {
            this.contacts.whatsapp = data.data.filter(item => { return item.type == 'whatsapp' })[0].value
            this.contacts.facebook = data.data.filter(item => { return item.type == 'facebook' })[0].value
            this.contacts.x = data.data.filter(item => { return item.type == 'x' })[0].value
            this.contacts.instagram = data.data.filter(item => { return item.type == 'instagram' })[0].value
            this.contacts.contact = data.data.filter(item => { return item.type == 'contact' })[0].value
            this.contacts.email = data.data.filter(item => { return item.type == 'email' })[0].value
          }
          console.log(this.contacts);
        })
        .catch(error => {
          console.log(error);
        })
    }
  },
  beforeMount() {
    this.getContacts();
  },
  computed:{
    language(){
      return langStor.state.language
    }
  }
};
</script>

<style>
.footer{
  padding: 2rem 0;
  text-align: left;
}
.col_ar{
  display: flex;
}
[data-direction = rtl] .contact  {
    text-align: right !important;
}
[data-direction = rtl] .info  {
    text-align: right !important;
}
[data-direction = rtl] .about  {
    text-align: right !important;
}
.footer .container{
  background-color: #9FB18F;
  color: #FFF;
  border-radius: 10px;
  padding: 2rem;
}
.footer ul li a,
.footer .contact p,
.footer .contact .email a{
  font-size: 14px;
  font-weight: 400;
}
.footer ul li a,
.footer .contact .email a{
  text-decoration: none;
  color: #FFF;
}

/* Logo */
.footer .social .logo{
  margin: 0 auto;
  display: block;
  width: 9rem;
  filter: invert(0%) sepia(91%) saturate(2%) hue-rotate(6deg) brightness(114%) contrast(100%);
}
.footer .social .social-media{
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.footer .social .social-media a:nth-child(2){
  margin: 0 .5rem;
}

/* About, Info */
.footer h5{
  font-size: 18px;
  font-weight: 700;
}
.footer ul{
  padding: 0;
  list-style-type: none;
  line-height: 2;
}

/* Contact */
.footer .contact p{
  margin: 0;
}
.footer .contact .email{
  margin-top: 1rem;
}
.footer .contact .email img{
  margin-right: .5rem
}

/* Copy Write */
.footer hr{
  opacity: 1;
  width: 100%;
  border: none;
  height: 1px;
  background-color: #D2D2D2;
}
.footer .copy-write{
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
}
</style>
