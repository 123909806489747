<template>
  <div class="login" id="login">
    <div class="background">
      <div class="overlay"></div>
      <h3>{{ $t('Login') }}</h3>
    </div>
    <div class="container">
      <div class="form" @submit.prevent="login">
        <img src="@/assets/icons/login/login.svg" alt="Login Icon">
        <h1>{{ $t('Login') }}<br />{{ $t('Into Your Account') }}</h1>
        <form>
          <label>{{ $t('Email Address') }}*</label>
          <input type="email" v-model="loginData.email" :placeholder="$t('Enter Here')" required>
          <label>{{ $t('Password') }}</label>
          <input type="password" v-model="loginData.password" :placeholder="$t('Enter Here')">
          <router-link class="forgot-password" to="/ForgetPassword">{{ $t('FORGOT PASSWORD?') }}</router-link>
          <input class="submit-btn" type="submit" :value="$t('Login')" :disabled="isLoading ? true:false">
          <p class="error-message">{{ errorMessage }}</p>
        </form>
        <p class="signup-text">{{ $t('Dont Have An Account?') }}</p>
        <router-link class="signup-btn" to="/Signup">{{ $t('Sign Up') }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "LoginView",
  data() {
    return {
      loginData: {
        email: '',
        password: '',
      },
      errorMessage: '',
      carts:[],
      isLoading: false
    }
  },
  methods: {
    login() {
      this.isLoading = true;     

      fetch(this.serverURL + 'api/auth/login',{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: this.loginData.email,
          password: this.loginData.password,
          session_id: localStorage.getItem('sessionId'),
        })
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          
          if (data.status == 200) {
            localStorage.setItem('token', data.token);
            store.state.accessToken = localStorage.getItem('token');

            localStorage.setItem('user_info', JSON.stringify(data.data));
            store.state.userInfo = JSON.parse(localStorage.getItem('user_info'));
            
            let randomNumberString = Math.random().toString(36).substring(2, 10);

            const date = new Date();
            let currentTimeStamp = date.getTime().toString();
            console.log(randomNumberString + currentTimeStamp)
            localStorage.setItem('sessionId', randomNumberString + currentTimeStamp);
            // send cart to backend
            const localCart = localStorage.getItem('cartProducts');
            if(localCart){
              this.carts=JSON.parse(localCart);
              for(let i = 0; i < this.carts.length; i++){
                this.totalPrice += (this.carts[i]?.offer_price?this.carts[i].offer_price:this.carts[i].price) * parseInt(this.carts[i]?.cart_quantity);
                console.log(this.carts[i].offer_price,this.carts[i].price,parseInt(this.carts[i]?.cart_quantity),'1');
              }
              this.addToCart(this.carts)
            }
            this.$router.push({ name: "Home"});
          }else{
            this.errorMessage = data.message;
          }
        })
        .catch(error => {
          console.log(error);
          this.errorMessage = this.$t('Your account is deactivated');
        })
    },
    addToCart(carts) {
      console.log(this.carts,'carts');
      let data = {
        items: []
      };
      for(let i = 0; i < carts.length; i++){
        data.items.push({
          product_color_size_id: carts[i].color_size_id,
          quantity: carts[i]?.cart_quantity
        });
      }
      console.log(data,'OIOIOIOI');
      fetch(this.serverURL + 'api/catalog/cart/bulk-add',{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => { 
          if(data.status == 200)
            localStorage.setItem('myCarts', JSON.stringify([]));
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  mounted() {},
  computed: {
    token () {
      return store.state.accessToken;
    },
  },
  components: {
  },
};
</script>

<style>
/* Background */
.login .background{
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  position: relative;
  height: 10vh;
}
.login .background .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .74;
  background-color: #F7F6E2;
  z-index: 1;
}
.login .background h3{
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

/* Form */
.login .form{
  padding: 4rem 0 2rem;
  width: 50%;
  margin: 0 auto;
  text-align: left;
}
@media (max-width: 992px){
  .login .form{
    width: 60%;
  }
}
@media (max-width: 767px){
  .login .form{
    width: 70%;
  }
}
@media (max-width: 400px){
  .login .form{
    width: 80%;
  }
}
@media (max-width: 300px){
  .login .form{
    width: 90%;
  }
}
.login .form h1{
  font-size: 32px;
  font-weight: bold;
  color: #6F825F;
  margin: 2rem 0;
}
.login .form label{
  color: #6F825F;
  font-size: 14px;
  display: block;
  margin: .5rem 0;
}
.login .form label:not(:first-of-type){
  margin-top: 2rem;
}
.login .form input{
  width: 100%;
  background-color: #F5F5F5;
  border: none;
  outline: none;
  padding: .8rem 2rem;
  border-radius: 10px;
}
.login .form input::placeholder{
  color: #A2A2A2;
  font-size: 14px;
  font-weight: lighter;
}
.login .form .forgot-password{
  display: block;
  text-decoration: none;
  color: black;
  font-size: 14px;
  text-align: right;
  margin: 1rem 0 0 0;
}
.login .form .submit-btn{
  background-color: #B2CC9B;
  font-size: 18px;
  font-weight: bold;
  margin-top: 2rem;
}
.login .form .signup-text{
  color: black;
  font-size: 16px;
  margin-top: 2rem;
  text-align: center;
}
.login .form .signup-btn{
  display: block;
  font-size: 18px;
  font-weight: bold;
  background-color: #EAF1E4;
  padding: .8rem 2rem;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  color: #6F825F;
}
</style>