<template>
    <div class="delete-item-modal" id="delete-item-modal">
      <!-- Modal -->
      <div
          class="modal fade"
          id="DeleteItemModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content
          ">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ $t('Delete Item') }}</h5>
            </div>
            <div class="modal-body">
              {{ $t('Are You Sure?') }}
            </div>
            <div class="modal-footer">
              <button
                  type="button"
                  class="no-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              >{{ $t('No') }}</button>
              <button
                  type="button"
                  class="yes-btn"
                  @click="$emit('deleteItem')"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              >{{ $t('Yes') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  
  export default {
    name: "DeleteAddressView",
    data() {
      return {
      }
    },
    methods: {
   
    },
    watch:{
    },
    computed: {
    },
  };
  </script>
  
  <style scoped>
  .delete-item-modal .modal{
    background-color: #E7E7E7;
    padding-top: 30vh;
  }
  .delete-item-modal .modal .modal-content {
    border-radius: 10px;
    padding: 2rem 1.5rem;
    border: none;
  }
  .delete-item-modal .modal .modal-header{
    border-bottom: none;
    padding: 0;
  }
  .delete-item-modal .modal .modal-title {
    color: #6F825F !important;
    font-size: 18px !important;
  }
  .delete-item-modal .modal .modal-body{
    text-align: left;
    margin-top: .5rem;
    color: #1E181A;
    font-size: 15px;
    padding: 0;
  }
  .delete-item-modal .modal .modal-footer{
    padding: 0;
    border-top: 0;
    margin-top: 1rem;
  }
  .delete-item-modal .modal .yes-btn,
  .delete-item-modal .modal .no-btn{
    border: none;
    font-size: 14px;
    font-weight: bold;
    border-radius: 10px;
    padding: .7rem 2rem;
  }
  .delete-item-modal .modal .yes-btn{
    color: #FFF !important;
    background-color: #B2CC9B !important;
  }
  .delete-item-modal .modal .no-btn{
    color: #000 !important;
    background-color: transparent !important;
  }
  </style>
  