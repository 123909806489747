<template>
  <div class="cart" id="reset-password">
    <Loading v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="false"
      color="#B2CC9B"
      loader="bars"
      background-color="#000"
      :lock-scroll="false"
    />
    <div class="background">
      <div class="overlay"></div>
      <h3>{{ $t('Cart') }}</h3>
    </div>
    <div class="container">
      <div class="no-data">
        <!-- <img src="@/assets/icons/empty-logo.svg" alt="Empty Icon"> -->
        <h3>{{ $t('The payment was completed successfully') }}</h3>
        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p> -->
      </div>
    </div>
  </div>
  <DeleteItemModal @deleteItem="deleteTheItem(current_id)"></DeleteItemModal>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import langStor from '@/langStore'

import store from '@/store'
import DeleteItemModal from '@/views/deleteCart.vue'

export default {
  name: "CartView",
  data() {
    return {
      carts: [],
      totalPrice: 0,
      current_id:'',
      isLoading: false
    }
  },
  methods: {
  },
  beforeMount() {
      // this.getCarts();
  },
  mounted() {
   
    

    // Delete Icon Clicked
    // window.$('body').on('click', '.cart .container .cards .card .operations img', function (){
    //   let id = window.$(this).parents('.card').data('id');

    //     let newCarts = [];

    //     for(let i = 0; i < that.carts.length; i++){
    //       if (that.carts[i].cart_id == id){
    //         that.totalPrice -= ((that.carts[i].offer_price)&&that.handleDate(that.carts[i]?.offer_price_end)?that.carts[i].offer_price:that.carts[i].price) * that.carts[i].cart_quantity;
    
  },
  computed: {
    token () {
      return store.state.accessToken;
    },
    language(){
      return langStor.state.language
    }
  },
  components: {
    Loading,DeleteItemModal
  },
};
</script>

<style>
/* Background */
.cart .background{
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  position: relative;
  height: 10vh;
}
.cart .background .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .74;
  background-color: #F7F6E2;
  z-index: 1;
}
.cart .background h3{
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.cart .container{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* Columns */
.cart .container .columns{
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  color: #1E181A;
}

/* Cards */
.cart .container .cards{
  text-align: left;
}
.cart .container .cards .card{
  padding-bottom: 2rem;
  margin-top: 2rem;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #9B9A9A;
}
.cart .container .cards .card .row{
  align-items: center;
}
.cart .container .cards .card .product{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.cart .container .cards .card .product img{
  width: 5rem;
  margin-right: .5rem
}
.cart .container .cards .card .product .details p{
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin-bottom: .5rem;
}
.cart .container .cards .card .product .details span{
  color: #B2CC9B;
  font-size: 14px;
  display: block;
}
.cart .container .cards .card .product .details span:first-of-type{
  margin-bottom: .5rem;
}
.cart .container .cards .card .price span,
.cart .container .cards .card .total-price span{
  font-size: 16px;
  color: black;
}
.cart .container .cards .card .quantity .counter{
  background-color: #EAF1E4;
  padding: .5rem;
  color: #6F825F;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.cart .container .cards .card .quantity .counter .button{
  border-radius: 50%;
  padding: .2rem;
  width: 22px;
  border: 2px solid #6F825F;
  cursor: pointer;
}
.cart .container .cards .card .quantity .counter .button:first-of-type{
  padding: .49rem .3rem;
}
.cart .container .cards .card .quantity .counter .number{
  font-size: 16px;
  font-weight: bold;
  margin: 0 .5rem;
}
.cart .container .cards .card .operations img{
  cursor: pointer;
}

/* Totla */
.cart .container .total{
  background-color: #F7F6E2;
  border-radius: 10px;
  padding: 2rem;
}
@media(max-width: 1200px){
  .cart .container .total{
    margin-top: 2rem;
  }
}
.cart .container .total .price{
  font-size: 18px;
  color: #6F825F;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cart .container .total a{
  cursor: pointer;
  text-decoration: none;
  color: #FFF;
  font-size: 16px;
  font-weight: bold;
  background-color: #B2CC9B;
  border-radius: 10px;
  padding: .8rem .5rem;
  margin-top: 1rem;
  display: block;
}

/* No Data */
.cart .container .no-data{
  background-color: #F7F6E2;
  width: 29rem;
  margin: 15vh auto 0;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
}
@media (max-width: 500px){
  .cart .container .no-data{
    width: 90%;
  }
}
.cart .container .no-data h3{
  color: #6F825F;
  font-size: 20px;
  font-weight: bold;
  margin: 1rem 0;
}
.cart .container .no-data p{
  color: #6F825F;
  font-size: 16px;
}
</style>