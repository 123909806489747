<template>
  <div class="search-results" id="search-results">
    <Loading v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="false"
      color="#B2CC9B"
      loader="bars"
      background-color="#000"
      :lock-scroll="false"
    />
    <div class="background">
      <div class="overlay"></div>
      <h3>{{ $t('SEARCH RESULTS') }}</h3>
    </div>
    <div class="container">
      <p>{{ $t('SEARCH RESULTS FOR') }} "{{ query }}"</p>
      <div class="cards" v-if="products.length > 0">
        <div class="row">
          <div class="col-sm-6 col-md-4 col-lg-3" v-for="product in products" :key="product.id">
            <div class="card" :data-id="product.id" :data-name="product.name">
              <img :src="product.image" alt="Product Image" />
              <h5>{{product?.translations[0]?.locale.includes(language)?product?.translations[0]?.name:product?.translations[1]?.locale.includes(language)?product?.translations[1]?.name:product?.name }}</h5>
              <div class="prefix">
                <span>{{ product.offer_price?product.offer_price:product.price }} {{ $t('KD') }}</span>
                <img v-if="product.is_added_to_wishlist" src="@/assets/icons/red-heart.svg" data-value="remove" alt="Heart Icon" />
                <img v-else src="@/assets/icons/black-heart.svg" data-value="add" alt="Heart Icon" />
              </div>
              <span class="old-price">{{ product.offer_price?product.price + ' KD':'' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-data">
        <img src="@/assets/icons/empty-logo.svg" alt="Empty Icon">
        <h3>{{ $t('No Products Found') }}</h3>
        <p v-html="text?.content"></p>
      </div>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'
import langStor from '@/langStore'

export default {
  name: "SearchResultsView",
  props: {
    query: String
  },
  setup(){
    function createDebounce() {
            let timeout = null;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }
        return{
          debounce: createDebounce(),
        }
  },
  data() {
    return {
      products: [],
      home:[],
      isLoading: false
    }
  },
  methods: {
    getProducts() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/catalog/products?filter[search]=' + this.query, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          if(data.status == 200)
            this.products = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    addWishList(id) {
      fetch(this.serverURL + 'api/catalog/products/' + id +'/wishlist',{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200)
            location.reload();
        })
        .catch(error => {
          console.error(error);
        })
    },
    removeWishList(id) {
      fetch(this.serverURL + 'api/catalog/products/' + id +'/wishlist',{
        method:  'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200)
            location.reload();
        })
        .catch(error => {
          console.error(error);
        })
    },
    getHomeContent() {
      fetch(this.serverURL + 'api/cms/pages/home', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
          .then(response => response.json())
          .then(data => {
            if (data.status == 200)
              this.home = data.data;
          })
          .catch(error => {
            console.log(error);
          })
    },
  },
  beforeMount() {
    this.getProducts();
    this.getHomeContent()
  },
  mounted() {
    let that = this;

    // Card Clicked
    window.$('body').on('click', '.search-results .container .cards .card', function (){
      localStorage.setItem('product_id', window.$(this).data('id'));

      let fullPath = '/Product/' + window.$(this).data('id');
      that.$router.push({ path: fullPath });
    });

    // Heart Clicked
    window.$('body').on('click', '.search-results .container .cards .card .prefix img', function (e){
      e.stopImmediatePropagation();
      if(localStorage.getItem('token')=='' || !localStorage.getItem('token')){
        that.$router.push({ name: 'Login' });
      }
      else{
        let id = window.$(this).parents('.card').data('id');

        if(window.$(this).data('value') == 'add'){
          that.addWishList(id);
        }else{
          that.removeWishList(id);
        }
      }
    });
  },
  computed: {
    token () {
      return store.state.accessToken;
    },
    language(){
      return langStor.state.language
    },
    text(){
      return this.home?.translations ? this.home.translations.filter(item=>{
        return item.name == 'search' && item.locale == langStor.state.language
      })[0] : '';
    },
  },
  watch: {
    query(){
      if(this.query != '')
        this.getProducts();
    },
  },
  components: {
    Loading,
  },
};
</script>

<style>
/* Background */
.search-results .background{
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  position: relative;
  height: 10vh;
}
[data-direction = rtl] h5{
  text-align: right
}
.search-results .background .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .74;
  background-color: #F7F6E2;
  z-index: 1;
}
.search-results .background h3{
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

/* Container */
.search-results .container{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.search-results .container p{
  margin: 0;
}
.search-results .container > p{
  color: #B2CC9B;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
}

/* Cards */
.search-results .container .cards{
  padding-top: 2rem;
  text-align: left;
}
.search-results .container .cards .row > div{
  margin-bottom: 1rem;
}
.search-results .container .cards .card,
.search-results .container .cards .card > img{
  border-radius: 10px;
}
.search-results .container .cards .card{
  padding: .8rem;
  height: 100%;
  box-shadow: 6px 10px 25px 0 rgba(0, 0, 0, .1);
  border: none;
  cursor: pointer;
}
.search-results .container .cards .card > img{
  width: 100%;
}
.search-results .container .cards .card h5{
  color: black;
  font-size: 18px;
  margin: .8rem 0;
}
.search-results .container .cards .card .prefix{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-results .container .cards .card .prefix span{
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.search-results .container .cards .card .prefix img{
  background-color: #F7F6E2;
  padding: .5rem;
  border-radius: 10px;
}
.search-results .container .cards .card .old-price{
  color: #A2A2A2;
  font-size: 16px;
  text-decoration: line-through;
  text-align: left;
}

/* No Data */
.search-results .container .no-data{
  background-color: #F7F6E2;
  width: 29rem;
  margin: 15vh auto 0;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
}
@media (max-width: 500px){
  .search-results .container .no-data{
    width: 90%;
  }
}
.search-results .container .no-data h3{
  color: #6F825F;
  font-size: 20px;
  font-weight: bold;
  margin: 1rem 0;
}
.search-results .container .no-data p{
  color: #6F825F;
  font-size: 16px;
}
</style>