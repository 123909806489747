import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/main.css'

import store from './store'
import {i18n} from './lang'
import router from './router'
// import { createGtm } from '@gtm-support/vue-gtm'


// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// jQuery
window.$ = window.jQuery = require('jquery');

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
  },
})

// Animation
import 'animate.css';

// Perfect Scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

// Website Title
const link = document.querySelector("[rel='icon']")
link.setAttribute('href', '/logo.svg')

// Init Head Tag
import { createHead } from '@vueuse/head'
import useLangStore  from './langStore'
const head = createHead()

// Init store
store.actions.initializeAccessToken()
store.actions.initializeUserInfo()
store.actions.initializeAppLang()
store.actions.initializeAppMode()
console.log(localStorage.getItem('sessionId'))
if(!localStorage.getItem('sessionId'))
{
  let randomNumberString = Math.random().toString(36).substring(2, 10);

  const date = new Date();
  let currentTimeStamp = date.getTime().toString();

  localStorage.setItem('sessionId', randomNumberString + currentTimeStamp);
}
console.log(localStorage.getItem('sessionId'))

let app = createApp(App)

// Global URL
// app.config.globalProperties.serverURL = 'http://localhost:8000/'
app.config.globalProperties.serverURL = 'https://tiny-koala-backend.squaretech.tech/'
app.use(i18n)
app.use(router);
// app.use(
//     createGtm({
//       id: "GTM-1D2W86X49P",
//       source: 'https://www.googletagmanager.com/gtag/js',
//       vueRouter: router
//     })
//   );
app.use(head).use(vuetify).use(PerfectScrollbar).mount('#app')

let language = localStorage.getItem("language")??'en';
if(!['en','ar'].includes(language)) language='en';
localStorage.setItem("language", language );
i18n.setLocale(language);
document.documentElement.setAttribute('lang', language);
document.documentElement.dataset.direction = ['ar'].includes(language)?'rtl':'ltr';
document.documentElement.style.direction=['ar'].includes(language)?'rtl':'ltr';
useLangStore.actions.setLanguage(language);


const originalFetch = window.fetch;
window.fetch = async function (url, options) {
  try {
      const response = await originalFetch(url, options);

      if (response.status === 401) {
        localStorage.removeItem('token');
        store.state.accessToken = '';
        localStorage.removeItem('user_info');
        localStorage.removeItem('cartProducts');
        store.state.userInfo = null;
        let randomNumberString = Math.random().toString(36).substring(2, 10);

        const date = new Date();
        let currentTimeStamp = date.getTime().toString();

        localStorage.setItem('sessionId', randomNumberString + currentTimeStamp);
          return Promise.reject(new Error('Unauthorized'));
      }

      return response;
  } catch (error) {
      console.error("Error with the fetch request:", error);
      return Promise.reject(error);
  }
};
